import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";
import logo from "./logo.svg";
import {
  AiFillAndroid,
  AiFillApple,
} from "react-icons/ai";

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (isLoading) return null;
  if (isAuthenticated) {
    const os = getMobileOperatingSystem();

    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
              maxWidth: "1200px",
            }}
          >
            {(os === "iOS" || os === "unknown") && (
              <div>
                <h2>
                  <AiFillApple />
                  iPhone
                </h2>
                <ul>
                  <li>
                    <a
                      className="App-link"
                      href="itms-services://?action=download-manifest&url=https://big-mobile.dx-np.foc.zone/assets/alpha/test/manifest.plist"
                    >
                      Install TEST build
                    </a>
                  </li>
                  <li>
                    <a
                      className="App-link"
                      href="itms-services://?action=download-manifest&url=https://big-mobile.dx-np.foc.zone/assets/alpha/beta/manifest.plist"
                    >
                      Install BETA build
                    </a>
                  </li>
                  <li>
                    <a
                      className="App-link"
                      href="itms-services://?action=download-manifest&url=https://big-mobile.dx-np.foc.zone/assets/alpha/prod/manifest.plist"
                    >
                      Install PROD build
                    </a>
                  </li>
                </ul>
              </div>
            )}
            {(os === "Android" || os === "unknown") && (
              <div>
                <h2>
                  <AiFillAndroid />
                  Android
                </h2>
                <ul>
                  <li>
                    <a
                      className="App-link"
                      href="https://big-mobile.dx-np.foc.zone/assets/alpha/test/BIGMobile.apk"
                    >
                      Download TEST build
                    </a>
                  </li>
                  <li>
                    <a
                      className="App-link"
                      href="https://big-mobile.dx-np.foc.zone/assets/alpha/beta/BIGMobile.apk"
                    >
                      Download BETA build
                    </a>
                  </li>
                  <li>
                    <a
                      className="App-link"
                      href="https://big-mobile.dx-np.foc.zone/assets/alpha/prod/BIGMobile.apk"
                    >
                      Download PROD build
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </header>
      </div>
    );
  } else {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <div onClick={loginWithRedirect} style={{textDecoration: "underline", cursor: "pointer"}}>Sign in with Rocket</div>
        </header>
      </div>
    );
  }
}

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export default App;
